import { useCallback, useEffect, useContext } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import AuthContext from "../context/authContext";
import { useHistory } from "react-router-dom";
export default function useCognito() {
	const config = require("../cognito.json");
	const { setIsAuthenticated, setCsrfToken } = useContext(AuthContext);
	const history = useHistory()
	const userPool = new CognitoUserPool({
		UserPoolId: config.aws_user_pools_id,
		ClientId: config.aws_user_pools_web_client_id,
	});

	const getAuthenticatedUser = useCallback(() => {
		const user = userPool.getCurrentUser();
		if (user !== null) setIsAuthenticated(true);
		return user;
	}, []);

	useEffect(() => {
		getAuthenticatedUser();
	}, [getAuthenticatedUser]);

	const signOut = () => {
		// logoutServer()
		// 	.then(() => {
		// 		setIsAuthenticated(false);
		// 		return userPool.getCurrentUser()?.signOut();
		// 	})
		// 	.catch((error) => {
		// 		console.error("Error while signing out:", error);
		// 	});

		// Sign out even if the backend is not available
		logoutServer()
		//removing user infos from local storage
		localStorage.removeItem('groups')
		localStorage.removeItem('userEmail')
		localStorage.removeItem('firstName')
		localStorage.removeItem('lastName')
		setIsAuthenticated(false);
		return userPool.getCurrentUser()?.signOut();
	};

	const signOutClient = () => {
		setIsAuthenticated(false);
		return userPool.getCurrentUser()?.signOut();
	};

	async function logoutServer() {
		// Call the logout API on the back-end

		// Retrieve the CSRF token from localStorage
		const csrfToken = localStorage.getItem("csrfToken");

		const headers = {
			"Content-Type": "application/json; charset=utf-8",
			"X-CSRF-Token": csrfToken, // Add the CSRF token to the request headers
		};

		const logoutResponse = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/${process.env.REACT_APP_AUTH_SERVICE_API_VERSION}/auth/logout`,
			{
				method: "POST",
				credentials: "include",
				headers: headers,
			}
		);

		// Check the response status code
		if (logoutResponse.status === 400) {
			console.error("Logout failed: Bad request");
		} else if (logoutResponse.status === 500) {
			console.error("Logout failed: Internal server error");
		} else if (logoutResponse.ok) {
			console.log("Logout successful");
		} else {
			const error = await logoutResponse.json();
			console.error("Logout failed:", error);
		}
	}

	return {
		userPool,
		getAuthenticatedUser,
		signOut,
		signOutClient,
	};
}
