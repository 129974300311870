import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';

const SondaRoute = ({ component: Component, isAuthenticated, showBasicMenu, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    showBasicMenu ? (
                        <Redirect to="/support" />
                    ) : (
                        // Use Suspense for lazy loading
                        <Suspense fallback={<div>Loading...</div>}>
                            <Component {...props} />
                        </Suspense>
                    )
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

export default SondaRoute;
