import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import "./styles/shared.scss";
import { AppProvider } from "./context/appContext";
import { ErrorProvider } from "./context/errorContext";
import { SnackbarProvider } from "./context/SnackbarContext.js";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<SnackbarProvider>
		<ErrorProvider>
			<AppProvider>
				<App />
			</AppProvider>
		</ErrorProvider>
	</SnackbarProvider>
); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
