import { useEffect, useState } from "react";

const useActivityMonitor = (idleDuration, onIdle, keepAliveInterval, keepAliveAction) => {
	const [lastActivity, setLastActivity] = useState(Date.now());

	useEffect(() => {
		const handleActivity = () => {
			setLastActivity(Date.now());
		};

		window.addEventListener("mousemove", handleActivity);
		window.addEventListener("keydown", handleActivity);
		window.addEventListener("click", handleActivity);

		return () => {
			window.removeEventListener("mousemove", handleActivity);
			window.removeEventListener("keydown", handleActivity);
			window.removeEventListener("click", handleActivity);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			const timeSinceLastActivity = Date.now() - lastActivity;

			if (timeSinceLastActivity > idleDuration) {
				onIdle();
			}
			if (timeSinceLastActivity < keepAliveInterval) {
				keepAliveAction();
			}
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, [lastActivity, idleDuration, onIdle, keepAliveInterval, keepAliveAction]);
};

export default useActivityMonitor;
