import React, { useCallback, useState } from "react";
import { browserName } from "react-device-detect";
import { Box, Card, Modal, Paper, Typography, Button, TextField, Snackbar } from "@mui/material";
import AWS from "aws-sdk";
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from "amazon-cognito-identity-js";

import {
	safariInstructions,
	chromeInstructions,
	firefoxInstructions,
	edgeInstructions,
	defaultInstructions,
} from "./texts";

const CertificateModal = ({ isOpen, onClose }) => {
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		border: "2px #000",
		borderRadius: 2,
		boxShadow: 24,
		flexGrow: 1,
		padding: 5,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	};

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [notification, setNotification] = useState({ open: false, message: "" });

	const handleNotificationClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNotification({ ...notification, open: false });
	};

	const downloadCertificate = useCallback(() => {
		const poolData = {
			UserPoolId: "eu-west-1_7BO1CKjGV",
			ClientId: "42a499ub844nmp8kad58l82cfq",
		};

		const userPool = new CognitoUserPool(poolData);
		const userData = {
			Username: username,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);
		const authenticationData = {
			Username: username,
			Password: password,
		};
		const authenticationDetails = new AuthenticationDetails(authenticationData);

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: (session) => {
				AWS.config.credentials = new AWS.CognitoIdentityCredentials({
					IdentityPoolId: "eu-west-1:585a74f2-c8f9-4324-b4cb-419225ebae38",
					Logins: {
						[`cognito-idp.eu-west-1.amazonaws.com/${poolData.UserPoolId}`]: session.getIdToken().getJwtToken(),
					},
				});

				// Set the region here
				AWS.config.region = "eu-west-1";

				AWS.config.credentials.refresh((error) => {
					if (error) {
						setNotification({ open: true, message: error.message || JSON.stringify(error) });
					} else {
						const s3 = new AWS.S3();
						const params = {
							Bucket: "sonda-app-certs",
							Key: "rootCA.crt",
						};

						s3.getObject(params, (err, data) => {
							if (err) {
								setNotification({ open: true, message: err.message || JSON.stringify(err) });
							} else {
								const blob = new Blob([data.Body], { type: "application/x-x509-ca-cert" });
								const link = document.createElement("a");
								link.href = window.URL.createObjectURL(blob);
								link.download = "rootCA.crt";
								link.click();
							}
						});
					}
				});
			},
			onFailure: (err) => {
				setNotification({ open: true, message: err.message || JSON.stringify(err) });
			},
		});
	}, [username, password]);

	const prepareInstructions = () => {
		switch (browserName) {
			case "Chrome":
				return chromeInstructions;
			case "Safari":
				return safariInstructions;
			case "Firefox":
				return firefoxInstructions;
			case "Edge":
				return edgeInstructions;
			default:
				return defaultInstructions;
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={{ ...style }}>
				<Typography align={"center"} id="modal-modal-title" variant="h6" component="h1">
					How to install the certificate
				</Typography>
				<Paper style={{ whiteSpace: "pre-wrap", margin: 10 }} elevation={0} id="modal-modal-description">
					{prepareInstructions()}
				</Paper>
				<Card style={{ padding: "2rem" }}>
					<Typography align={"center"} id="modal-modal-title" component="h2">
						Enter username and password to download the certificate
					</Typography>
					<TextField
						label="Username"
						variant="outlined"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						style={{ margin: "10px 0" }}
						fullWidth
					/>
					<TextField
						label="Password"
						variant="outlined"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						style={{ margin: "10px 0" }}
						fullWidth
					/>
					<Button className="bg-gradient-theme-left border-0" onClick={downloadCertificate}>
						Download certificate
					</Button>
				</Card>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={notification.open}
					autoHideDuration={6000}
					onClose={handleNotificationClose}
					message={notification.message}
					action={
						<React.Fragment>
							<Button color="secondary" size="small" onClick={handleNotificationClose}>
								CLOSE
							</Button>
						</React.Fragment>
					}
				/>
			</Box>
		</Modal>
	);
};

export default CertificateModal;
