import React from 'react';

// eslint-disable-next-line no-undef
const sourceUrl = process.env.REACT_APP_SOURCE_URL;

const SourceLink = props => {
  return (
    <a href={sourceUrl} target="_blank" rel="noopener noreferrer" {...props} />
  );
};

export default SourceLink;