import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Brightness4 as DarkModeIcon, Brightness7 as LightModeIcon } from "@mui/icons-material";
import { useThemeContext } from "../../context/ThemeContext";

const DarkModeToggle = () => {
  const { themeMode, toggleTheme } = useThemeContext();

   const switchTheme = () => {
    const newThemeMode = themeMode === "dark" ? "light" : "dark";
    toggleTheme(newThemeMode);
  };


  return (
    <Tooltip title={`Switch to ${themeMode === "dark" ? "Light" : "Dark"} Mode`} placement="right">
      <IconButton color="inherit" onClick={switchTheme}>
        {themeMode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default DarkModeToggle;
