export const SET_CERT_VALID = 'SET_CERT_VALID';
export const SET_CERT_INVALID = 'SET_CERT_INVALID';
export const SET_BACKEND_AVAILABLE = 'SET_BACKEND_AVAILABLE';
export const SET_BACKEND_UNAVAILABLE = 'SET_BACKEND_UNAVAILABLE';
export const SET_BACKEND_VERSION = 'SET_BACKEND_VERSION';
export const SET_FRONTEND_VERSION = 'SET_FRONTEND_VERSION';
export const SET_UPDATE_REQUESTED = 'SET_UPDATE_REQUESTED';
export const SET_UPDATING = 'SET_UPDATING';
export const SET_UPDATE_FINISHED = 'SET_UPDATE_FINISHED';
export const FLUSH_UPDATE_DATA = 'FLUSH_UPDATE_DATA';
export const FETCH_COMPATIBLE_VERSIONS = "FETCH_COMPATIBLE_VERSIONS";
export const FETCH_COMPATIBLE_VERSIONS_SUCCESS = "FETCH_COMPATIBLE_VERSIONS_SUCCESS";
export const FETCH_COMPATIBLE_VERSIONS_FAILURE = "FETCH_COMPATIBLE_VERSIONS_FAILURE";

