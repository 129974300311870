import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import useStyles from '../../constants/commonStyles';


const Footer = () => {
  const theme = useTheme(); 
  const classes = useStyles(theme);
  return (
    <div className={classes.footer}>
      <Typography variant="body1" color="textSecondary">
        © 2023 Reperio B.V.
      </Typography>
    </div>
  );
};

export default Footer;
