import { Component } from 'react';
import { ErrorContext } from '../context/errorContext';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static contextType = ErrorContext; 
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);

    // Accessing context value using this.context
    const { handleError} = this.context;

    // Showing the error message using the handleError method from ErrorContext
    handleError(error, 'Something went wrong. Please try again later.');
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
