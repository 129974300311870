import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAppContext } from '../context/appContext';

const useHealthCheck = (url, signOut) => {
    const { setHealthStatus, isAcquireRunning } = useAppContext();
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (!isAcquireRunning) {
            const fetchStatus = () => {

                axios.get(url)
                    .then(response => {
                        if (response.data.readiness === "starting") {
                            const { progress: progressValue } = response.data;
                            setHealthStatus("starting")
                            setProgress(progressValue);
                        } else if (response.status === 200) {
                            setHealthStatus("up");
                        } else {
                            setHealthStatus("down");
                            setProgress(0);
                        }
                        if (response.status === 401 || response.status === 403) {
                            signOut();
                        }
                    })
                    .catch(error => {
                        setHealthStatus("down");
                        setProgress(0);
                        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                            signOut();
                        }
                    });

            }

            const interval = setInterval(fetchStatus, 1000);
            return () => clearInterval(interval);
        }
    }, [url, setProgress, signOut]);

    return { progress };
};

export default useHealthCheck;

