import React, { createContext, useContext } from 'react';
import { useSnackbar } from './SnackbarContext';

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const { openSnackbar } = useSnackbar();

  const handleError = (error, errorMessage = 'An error occurred. Please try again later.') => {
    console.error('An error occurred:', error);
    setTimeout(() => {
      openSnackbar('error', errorMessage);
    }, 500);
  };

  return (
    <ErrorContext.Provider value={{ handleError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  return useContext(ErrorContext);
};

export { ErrorContext };
