import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as FaIcons from "react-icons/fa";
import React from "react";
import { useBackendStatusContext } from "../context/backendStatus/backendStatusContext";
import { SET_UPDATE_REQUESTED } from "../context/backendStatus/backendStatusActions";
import { updateBackend } from "../actions/backendVersionActions";
import { useTheme } from '@mui/material';

const UpdateButton = ({ forceShow = false, selectedVersion }) => {
	const backendStatus = useBackendStatusContext();
	const theme = useTheme(); 

	const handleUpdate = async () => {
		backendStatus.dispatch({ type: SET_UPDATE_REQUESTED });

		// Use targetVersion from state if selectedVersion is null
		const versionToUpdate = selectedVersion || backendStatus.state.targetVersion;
		await updateBackend(versionToUpdate);
	};

	return (
		(forceShow || backendStatus.state.isUpdatePossible) && (
			<Box pr={3} sx={{ display: "flex", alignItems: "center", color: theme.palette.primary.main }}>
				<Typography>Device update available</Typography>
				<LoadingButton
					className="float-sm-end m-1"
					variant="outlined"
					startIcon={<FaIcons.FaDownload />}
					onClick={handleUpdate}
					loading={backendStatus.state.isUpdateRequested}
					size={"small"}
				>
					INSTALL
				</LoadingButton>
			</Box>
		)
	);
};

export default UpdateButton;
