import React, { createContext, useState, useContext, useEffect } from "react";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import {themes} from "../constants/theme";

const ThemeContext = createContext();



export const ThemeProvider = ({ children }) => {
  const storedTheme = localStorage.getItem("theme");
   const initialTheme = storedTheme ? JSON.parse(storedTheme) : "light"; 

  const [themeMode, setThemeMode] = useState(initialTheme);

  const toggleTheme = (newTheme) => {
 setThemeMode(newTheme);
  };


  const getTheme = (themeMode) => createTheme({
    palette: {
      mode: themeMode,
      ...themes[themeMode],
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.common,
            backgroundImage: "none",
                      
          }),
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.common,
          }),
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          }),
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            height: '358px',
            overflowY: 'auto',
          },
        },
      },
    },
        
  }); 


  // Saving the current theme preference to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(themeMode));
  }, [themeMode]);

   const currentTheme = getTheme(themeMode);

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
     <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};
