export const safariInstructions =
`Instructions for Safari browser:
1. Open the Keychain Access application on your Mac. You can find it by searching for "Keychain Access" in Spotlight or by navigating to Applications > Utilities > Keychain Access.
2. In the Keychain Access application, select the "System" keychain from the left-hand sidebar.
3. Click the "File" menu in the menu bar, and then select "Import Items" from the drop-down menu.
4. In the "Import" window, navigate to the location where you saved the root certificate file, select it, and then click the "Open" button.
5. In the "Keychain Access" window that appears, enter the administrator username and password for your Mac, and then click the "Modify Keychain" button.
6. In the "Add Certificates" window, verify that the certificate information is correct, and then click the "Add" button.
7. Close the Keychain Access application.
8. Open the Safari browser.
9. In the Safari menu, select "Preferences."
10. Click the "Privacy" tab.
11. Click the "Manage Website Data" button.
12. In the "Website Data" window, search for the website that you want to use the imported root certificate with.
13. Select the website, and then click the "Remove" button.`;

export const chromeInstructions =
`Instructions for Chrome browser:
1. Click on the three-dot menu in the top right corner of the browser and select "Settings."
2. Scroll down and click on "Advanced" at the bottom of the page.
3. Under "Privacy and security," click on "Manage certificates."
4. Click on the "Authorities" tab.
5. Click on "Import."
6. Browse to the location of the root certificate file on your computer and select it.
7. Click "Open."
8. In the "Certificate Store" window, select "Trusted Root Certification Authorities."
9. Click "OK" to import the certificate.`;

export const firefoxInstructions =
`Instructions for Firefox browser:
1. Click on the three-line menu in the top right corner of the browser and select "Options."
2. Scroll down to the "Certificates" section and click on "View Certificates."
3. Click on the "Authorities" tab.
4. Click on "Import."
5. Browse to the location of the root certificate file on your computer and select it.
6. Click "Open."
7. In the "Certificate Store" window, select "Trust this CA to identify websites."
8. Click "OK" to import the certificate.`;

export const edgeInstructions =
`Instructions for Edge browser:
1. Open the Edge browser and click on the three dots (...) in the upper-right corner of the window.
2. From the drop-down menu, select "Settings."
3. Scroll down and click on "Privacy, search, and services" in the left-hand menu.
4. Scroll down to the bottom and click on "Manage certificates."
5. In the "Certificates" window, click on the "Trusted Root Certification Authorities" tab.
6. Click on the "Import" button at the bottom of the window.
7. In the "Certificate Import Wizard," click on the "Next" button.
8. Click on the "Browse" button and navigate to the location where your root certificate file is saved.
9. Select the root certificate file and click on the "Open" button.
10. Click on the "Next" button.
11. Select "Place all certificates in the following store" and click on the "Browse" button.
12. Select "Trusted Root Certification Authorities" and click on the "OK" button.
13. Click on the "Next" button.
14. Click on the "Finish" button.
15. You will see a message confirming that the certificate has been imported successfully.`;

export const defaultInstructions =
`We don't have instructions for your browser. Below are the instructions for the Chrome browser: \n` + chromeInstructions