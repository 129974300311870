import { styled, keyframes, Paper, Box, TableCell, ToggleButtonGroup, Card, IconButton, Button} from '@mui/material';
import {ListItem }from "@material-ui/core";
import { TableContainer } from '@mui/material';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";


export const StyledItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.background.common,
})); 

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const StyledDividerBox = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledLabelBox = styled(Box)(({ theme }) => ({
  border: `1px solid black`,
  width: 760,
  height: 640,
  backgroundColor: "white",
  color : "black"
}));

export const StyledTableContainer = styled(TableContainer)(() => ({
    height: "100%",
})); 

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: "bold",
	color: theme.palette.common.white,
}));

export const UsersTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: 4,
    margin: "10px 10px",
    maxWidth: "960px",
    backgroundColor: theme.palette.background.common,
})); 


//urlChecker

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingIcon = styled(AutorenewIcon)(({theme}) => ({
    animation: `${rotate} 2s linear infinite`,
    color: theme.palette.info.main
}));

export const StyledCardContainer = styled(Card)(({theme}) => ({
  backgroundColor: theme.palette.background.common,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  width: '50px',
  height: '40px',
  borderRadius: '5px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  
})); 

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
})); 

export const StyledListItem = styled(ListItem)(({ theme }) => ({
 root: {
    "&:hover": {
      color: "white",
    },
  },
})); 


export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
        top: -50,
        right: -330,
        fontSize: 24,
        overflow: "visible",
        position: "relative",
        whiteSpace: "nowrap",
        display: "inline-block",
        cursor: "pointer",
        color: theme.palette.main,
        transition: "color 0.3s ease, transform 0.3s ease",
        "&:hover": {
            color: theme.palette.error.main,
            transform: "scale(1.1)",
        },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
          '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
          },
          '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: theme.palette.primary.main,
          }
        },
    }));


export const getStatusTypographyStyle = (status, theme) => ({
  color:
    status === "down"
      ? theme.palette.error.main
      : status === "starting"
      ? theme.palette.warning.light
      : status === "checking"
      ? theme.palette.info.main
      : theme.palette.primary.main,
});


