import { useContext } from "react";
import AuthContext from "./context/authContext";

// Add a leading zero if the value is less than 10
function padTo2Digits(num) {
	return num.toString().padStart(2, "0");
}

// Format date of birth
function formatDOB(date) {
	const day = padTo2Digits(date.getDate())
	const month = padTo2Digits(date.getMonth() + 1)
	const year = date.getFullYear()


	return `${year}-${month}-${day}`;
}
//checks if the connected user is an admin and he belongs to reyedar group
//the email is the sended email to check if the user we want to modify is the same admin or not
function isAdminInReyedarGroupIsUser(userGroup,userEmail,email) {
	return userEmail === email &&  userGroup.includes("Reyedar")

}

export { formatDOB, isAdminInReyedarGroupIsUser };
