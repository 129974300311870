import { Content, Footer, Header, Sidebar } from '../Layout'
import React from 'react';
import {
  MdImportantDevices,
  MdLoyalty,
} from 'react-icons/md';

class MainLayout extends React.Component {

  static isSidebarOpen() {
    return document
      .querySelector('.ss-sidebar')
      .classList.contains('ss-sidebar--open');
  }

  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);

    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      this.notificationSystem.addNotification({
        title: <MdImportantDevices />,
        message: 'Welcome to SONDA Admin!',
        level: 'info',
      });
    }, 1500);

    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      this.notificationSystem.addNotification({
        title: <MdLoyalty />,
        message:
          'SONDA is developed by Reyedar B.V.!',
        level: 'info',
      });
    }, 2500);
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    const sidebar = document.querySelector('.ss-sidebar');
    if (!sidebar) {
      return;
    }
    if (openOrClose === 'open') {
      sidebar.classList.add('ss-sidebar--open');
    } else {
      sidebar.classList.remove('ss-sidebar--open');
    }
  }

  render() {
    const { children } = this.props;

    return (
      <main className="ss-app">
        <Sidebar />
        <Content onClick={this.handleContentClick}>
          <Header currentUser={this.props.currentUser}/>
          {children}
          <Footer />
        </Content>
      </main>
    )
  }
}

export default MainLayout;
