import React from 'react';
import notFound from "./../../assets/img/notFound/notFound.png"

const NotFoundPage = () => {
    const styles = {
        container: {
            width: '90vw', // Full viewport width
            height: '100vh', // Full viewport height
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        image: {
            maxWidth: '100%', // Ensures image does not exceed the container width
            maxHeight: '100%', // Ensures image does not exceed the container height
            objectFit: 'contain' // Adjusts the image size to maintain its aspect ratio
        }
    };

    return (
        <div style={styles.container}>
            <img src={notFound} alt='Not Found' style={styles.image} />
        </div>
    );
};

export default NotFoundPage;
