import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "../../services/httpService/httpService";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material";
import { Typography, Stack, Box } from "@mui/material";
import useCognito from "../../hooks/useCognito";
import { useAppContext } from "../../context/appContext";
import { RotatingIcon, getStatusTypographyStyle } from "../../constants/styledComponents";
import useHealthCheck from "../../hooks/useHealthCheck";


const UrlChecker = ({ circleSize, reverse, url, upAlias, downAlias }) => {
	const direction = reverse ? "row-reverse" : "row";
	const theme = useTheme(); 

	const { signOut } = useCognito();
	const { healthStatus } = useAppContext();
    const {progress } = useHealthCheck(url, signOut);
	const renderStatusIndicator = () => {
		const statusStyle = getStatusTypographyStyle(healthStatus, theme);
		switch (healthStatus) {
			case "up":
				return <CheckCircleIcon style={{ fontSize: circleSize, ...statusStyle }} />;
			case "down":
				return <ErrorIcon style={{ fontSize: circleSize, ...statusStyle }} />;
			case "starting":
				return (
					<CircularProgress
						variant="determinate"
						value={progress}
						style={{ color: statusStyle.color }}
						size={circleSize - 2}
					/>
				);
			case "checking":
				return <RotatingIcon style={{ fontSize: circleSize }} />;
			default:
				return <AutorenewIcon style={{ fontSize: circleSize, ...statusStyle }} />;
		}
	};

	return (
			<Stack spacing={1} direction={direction} marginRight={1}>
				{renderStatusIndicator()}
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography style={getStatusTypographyStyle(healthStatus, theme)}>
						SONDA device is{" "}
						{healthStatus === "up"
							? upAlias
							: healthStatus === "down"
							? downAlias
							: healthStatus === "starting"
							? "starting..."
							: "checking..."}
					</Typography>
				</Box>
			</Stack>
	);
};

// Prop type validation
UrlChecker.propTypes = {
    circleSize: PropTypes.number.isRequired, // Ensure circleSize is a required number
    reverse: PropTypes.bool.isRequired, // Ensure reverse is a required boolean
    url: PropTypes.string.isRequired,
    upAlias: PropTypes.string.isRequired,
	downAlias: PropTypes.string.isRequired,
};

export default UrlChecker;
