import {
	SET_CERT_VALID,
	SET_CERT_INVALID,
	SET_BACKEND_AVAILABLE,
	SET_BACKEND_UNAVAILABLE,
	SET_BACKEND_VERSION,
	SET_FRONTEND_VERSION,
	SET_UPDATE_REQUESTED,
	SET_UPDATING,
	SET_UPDATE_FINISHED,
	FLUSH_UPDATE_DATA,
	FETCH_COMPATIBLE_VERSIONS,
	FETCH_COMPATIBLE_VERSIONS_SUCCESS,
	FETCH_COMPATIBLE_VERSIONS_FAILURE,
} from "./backendStatusActions";

import appInfo from "../../../package.json";
import { useAppContext } from "../appContext";

const reducer = (state, action) => {
	switch (action.type) {
		case SET_CERT_VALID:
			return {
				...state,
				isCertificateValid: true,
			};
		case SET_CERT_INVALID:
			return {
				...state,
				isCertificateValid: false,
			};
		case SET_BACKEND_AVAILABLE:
			return {
				...state,
				isAvailable: true,
			};
		case SET_BACKEND_UNAVAILABLE:
			return {
				...state,
				isAvailable: false,
			};
		case SET_BACKEND_VERSION:
			// Check if action.payload is defined
			if (!action.payload) {
				console.error("Undefined payload for SET_BACKEND_VERSION");
				return { ...state, isFetchingCompatibleVersions: false };
			}
			return {
				...state,
				version: action.payload.version,
			};
		case SET_FRONTEND_VERSION:
			return {
				...state,
				frontendVersion: appInfo.version, // Update the frontend version in the state
			};
		case FETCH_COMPATIBLE_VERSIONS:
			return {
				...state,
				isFetchingCompatibleVersions: true,
				compatibleVersionsError: null,
			};

		case FETCH_COMPATIBLE_VERSIONS_SUCCESS:
			{
			if (!action.payload.data) {
				return {
					...state,
					isFetchingCompatibleVersions: false,
					fetchVersionsError: "No data received",
				};
			}

			const currentBackendVersion = state.version; // Assuming state.version holds the current backend version
			const currentFrontendVersion = appInfo.version; // Assuming state.frontendVersion holds the current frontend version
			
			const { bypassCompatibility } = useAppContext();
			const savedCompatibility = localStorage.getItem("compatibility")

			let isBackendCompatibleWithFrontend = JSON.parse(savedCompatibility) ?? bypassCompatibility;

			// Find the current frontend version in the compatibility matrix
			const frontendVersionInfo = action.payload.data.frontendVersions.find(
				(fVersion) => fVersion.version === currentFrontendVersion
			);
			
			// Check if the current backend version is in the compatibleBackends
			if (frontendVersionInfo && frontendVersionInfo.compatibleBackends.includes(currentBackendVersion)) {
				isBackendCompatibleWithFrontend = true;
			} else {
				isBackendCompatibleWithFrontend = false;
			}
			
			// Filter to find backend versions with currentBackendVersion as a compatible previous version
			const backendVersionsWithCurrentAsCompatiblePrevious = action.payload.data.backendVersions
				.filter(backendVersion => {
					const compatiblePrevious = backendVersion.compatiblePreviousVersions;
					return compatiblePrevious && Object.keys(compatiblePrevious).includes(currentBackendVersion);
				});

			// Find the closest next version to the currentBackendVersion
			let closestNextBackendVersion = null;
			if (backendVersionsWithCurrentAsCompatiblePrevious.length > 0) {
				closestNextBackendVersion = backendVersionsWithCurrentAsCompatiblePrevious
					.filter(backendVersion => backendVersion.version > currentBackendVersion)
					.reduce((closest, backendVersion) => {
						return (!closest || backendVersion.version < closest.version) ? backendVersion : closest;
					}, null)?.version;
			}
			
			let isUpdateRequired = false;
			let isUpdatePossible = false;

			// Determine if an update is required based on compatibility and/or version advancement.
			if (!isBackendCompatibleWithFrontend && (closestNextBackendVersion && currentBackendVersion !== closestNextBackendVersion)) {
				isUpdateRequired = true;
			}

			// Update isUpdatePossible based on closestNextBackendVersion
			if (closestNextBackendVersion !== null) {
				isUpdatePossible = true;
			}

			return {
				...state,
				isBackendCompatibleWithFrontend, // Add this new state to indicate compatibility
				compatibleVersions: action.payload.data,
				targetVersion: closestNextBackendVersion,
				isUpdateRequired,
				isUpdatePossible,
				isFetchingCompatibleVersions: false,
			};
		}

		case FETCH_COMPATIBLE_VERSIONS_FAILURE:
			return {
				...state,
				compatibleVersionsError: action.payload,
				isFetchingCompatibleVersions: false,
				data: action.payload.data,
				error: action.payload.error,
			};
		case SET_UPDATE_REQUESTED:
			return {
				...state,
				isUpdateRequested: true,
			};
		case SET_UPDATING:
			return {
				...state,
				isUpdateRequested: true,
				isUpdating: true,
			};
		case SET_UPDATE_FINISHED:
			return {
				...state,
				isUpdateRequested: false,
				isUpdating: false,
				updateMessage: {
					message: action.payload.message,
					status: action.payload.status,
				},
			};
		case FLUSH_UPDATE_DATA:
			return {
				...state,
				updateMessage: {
					message: "",
					status: "",
				},
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

export default reducer;
