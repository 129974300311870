import React from 'react';
import bn from '../../utils/bemnames';

const bem = bn.create('content');

const Content = ({ tag: Tag = 'div', className, ...restProps }) => {
  const classes = bem.b(className);

  return <Tag className={classes} {...restProps} />;
};

export default Content;