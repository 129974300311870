import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import { StyledCloseIcon } from "../../constants/styledComponents";


const ConfirmationDialog = ({
    title,
    subtitle,
    isOpen,
    onClose,
    onConfirm,
}) => {


    return (
        <>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={isOpen}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEnforceFocus
                    PaperProps={{
                        sx: {
                            overflow: "visible",
                        },
                    }}
                >
                    <DialogTitle fontSize={48} id="alert-dialog-title">
                        <React.Fragment>
                            <Box display="flex" width="auto" height={70}>
                                <Box
                                    m="auto"
                                    style={{
                                        whiteSpace: "normal",
                                        display: "inline-block",
                                    }}
                                >
                                    <StyledCloseIcon
                                        onClick={onClose}
                                    />
                                    <HelpOutlineIcon
                                        color="warning"
                                        style={{
                                            top: -80,
                                            fontSize: 120,
                                            overflow: "visible",
                                            position: "relative",
                                            whiteSpace: "nowrap",
                                            display: "inline-block",
                                        }}
                                    />
                                </Box>
                            </Box>
                        </React.Fragment>
                        <Typography variant="h5" align="center" style={{ display: "block" }}>
                            {title}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="body1" align="center">
                                {subtitle}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button
                            id="dialogCancel"
                            variant="outlined"
                            onClick={onClose}
                            startIcon={<CancelIcon />}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="dialogAbort"
                            variant="contained"
                            onClick={(e) => onConfirm(e)}
                            startIcon={<CheckCircleIcon />}
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
        </>
    );
};

export default ConfirmationDialog;

