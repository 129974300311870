import { CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

// Configuration constants

// eslint-disable-next-line no-undef
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
// eslint-disable-next-line no-undef
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
// eslint-disable-next-line no-undef
const REGION = process.env.REACT_APP_REGION;
// eslint-disable-next-line no-undef
const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
// eslint-disable-next-line no-undef
const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
// eslint-disable-next-line no-undef
const FILE_KEY = process.env.REACT_APP_FILE_KEY;

// Initialize the AWS configuration
AWS.config.region = REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: IDENTITY_POOL_ID });

// Helper function to retrieve the Cognito User Pool
const getUserPool = () => new CognitoUserPool({ UserPoolId: USER_POOL_ID, ClientId: CLIENT_ID });

// Helper function to get current Cognito user session
const getCurrentUserSession = () => {
	const userPool = getUserPool();
	const cognitoUser = userPool.getCurrentUser();

	// if (!cognitoUser) {
	// 	console.log("No current user found");
	// } else {
	// 	console.log("Current user found, attempting to get session");
	// }

	return new Promise((resolve, reject) => {
		if (!cognitoUser) {
			reject(new Error("No current user"));
		} else {
			cognitoUser.getSession((err, session) => {
				if (err) {
					reject(err);
				} else if (session.isValid()) {
					resolve(session);
				} else {
					reject(new Error("Session is invalid"));
				}
			});
		}
	});
};

// Helper function to refresh AWS credentials using the Cognito session
const refreshAWSCredentials = async (session) => {
	const loginKey = `cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`;

	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: IDENTITY_POOL_ID,
		Logins: { [loginKey]: session.getIdToken().getJwtToken() },
	});

	await AWS.config.credentials.getPromise();
};

// Function to safely parse JSON content
const parseJSON = (content) => {
	try {
		return JSON.parse(content);
	} catch (error) {
		throw new Error("Failed to parse JSON content");
	}
};

// Function to fetch compatible versions from S3
export const compatibleVersions = async () => {
	try {
		// Retrieve and set up the user's AWS credentials
		const session = await getCurrentUserSession();
		await refreshAWSCredentials(session);

		// Create an S3 service object
		const s3 = new AWS.S3();
		const params = { Bucket: BUCKET_NAME, Key: FILE_KEY };

		// Fetch the object from S3
		const data = await s3.getObject(params).promise();

		// Parse and return the compatibility matrix
		return parseJSON(data.Body.toString("utf-8"));
	} catch (error) {
		console.error("Error fetching the compatible versions:", error);
		console.error(`Error details: ${error.code} - ${error.message}`);
		throw error; // Rethrowing the error might be useful if you want to handle it further up the chain.
	}
};
