import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SnackbarContext } from '../../../context/SnackbarContext';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BasicSnackbar = () => {
  const { snackbarState, closeSnackbar } = useContext(SnackbarContext);

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={6000}
      onClose={closeSnackbar}
    >
      <Alert
        onClose={closeSnackbar}
        severity={snackbarState.severity}
        style={{ color: "#fff" }}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}; 

export default BasicSnackbar;
