import { useEffect, useState } from 'react';

/**
 * Hook to detect user inactivity/idle time.
 *
 * @param {number} idleDuration - Duration in milliseconds after which a user is considered idle.
 * @param {Function} onIdle - Callback function to invoke when the user is considered idle.
 *
 * @returns {void}
 */
const useIdleTimer = (idleDuration, onIdle) => {
    // State to keep track of the last detected user activity time.
    const [lastActivity, setLastActivity] = useState(Date.now());

    useEffect(() => {
        /**
         * Handler to update the last activity time whenever a user activity is detected.
         */
        const handleActivity = () => {
            setLastActivity(Date.now());
        };

        // Add event listeners for various user activities.
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('click', handleActivity);

        // Cleanup - Remove the event listeners when the component using this hook is unmounted.
        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('click', handleActivity);
        };
    }, []); // The empty dependency array ensures this effect runs once, similar to componentDidMount.

    useEffect(() => {
        // Set an interval to check if the user has been idle for more than the specified duration.
        const interval = setInterval(() => {
            if (Date.now() - lastActivity > idleDuration) {
                onIdle();
            }
        }, 1000);

        // Cleanup - Clear the interval when the component using this hook is unmounted.
        return () => {
            clearInterval(interval);
        };
    }, [lastActivity, idleDuration, onIdle]); // Re-run the effect whenever lastActivity, idleDuration, or onIdle changes.
};

export default useIdleTimer;
