import React, { createContext, useContext, useState } from 'react';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const openSnackbar = (severity, message) => {
    setSnackbarState({
      open: true,
      severity,
      message,
    });
  };

const closeSnackbar = () => {
  console.log('Closing Snackbar');
  setSnackbarState((prevState) => ({
    ...prevState,
    open: false,
  }));
};

  return (
    <SnackbarContext.Provider value={{ snackbarState, openSnackbar, closeSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
