export const formatName = (firstName, lastName) => {
    // Check if the the last name is not there, then it's an Admin user which belogns to a group 
    if (lastName === "") {
        return firstName;  // Directly return the capitalized version
    }

    // Get the first letter of the first name, capitalize it
    const initial = firstName.charAt(0).toUpperCase();

    // Capitalize the first letter of the last name and make the rest lowercase
    const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();

    // Combine the initial and formatted last name
    return `${initial}. ${formattedLastName}`;
};
