import React, { useContext, lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useBackendStatusContext } from '../context/backendStatus/backendStatusContext';
import { MainLayout } from './Layout';
import PageSpinner from './PageSpinner';
import AuthContext from '../context/authContext';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import SondaRoute from '../Routes';

const TestPage = lazy(() => import('../pages/TestPage'));
const ResultsPage = lazy(() => import('../pages/Results/Results'));
const UsersPage = lazy(() => import('../pages/Users'));
const SettingsPage = lazy(() => import('../pages/Settings'));
const SupportPage = lazy(() => import('../pages/Support'));
const AboutPage = lazy(() => import('../pages/About'));

const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const Router = () => {
    const backendStatus = useBackendStatusContext();
    const showBasicMenu = (!backendStatus.state.isAvailable || !backendStatus.state.isCertificateValid) && !backendStatus.state.isUpdating;
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <BrowserRouter basename={getBasename()}>
            <MainLayout>
                <Suspense fallback={<PageSpinner />}>
                    <Switch>
                        <SondaRoute exact path="/" component={TestPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />
                        <SondaRoute exact path="/results" component={ResultsPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />
                        <SondaRoute exact path="/users" component={UsersPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />
                        <SondaRoute exact path="/settings" component={SettingsPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />
                        <SondaRoute exact path="/support" component={SupportPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />
                        <SondaRoute exact path="/about" component={AboutPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />
                        {/* This route will match when no other routes match */}
                        <SondaRoute path="*" component={NotFoundPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />
                    </Switch>
                </Suspense>
            </MainLayout>
        </BrowserRouter>
    );
};

export default Router;
