import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 350,
    },
    usersTable: {
        minWidth: 650,
    },
    tableCell: {
        width: 100,
        "&.MuiTableCell-root": {
            padding: "6px",
        },
    },
    tableHeaderCell: {
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    oddRow: {
        backgroundColor: theme.palette.background.paper, // Very light grey for odd rows
    },
    stepper: {
        padding: theme.spacing(3),
    },
    status: {
        fontWeight: "bold",
        fontSize: "0.75rem",
        color: "white",
        borderRadius: 8,
        padding: "3px 10px",
        display: "inline-block",
    },
       userName: {
        fontWeight: "bold",
    },

    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    buttonBack: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    paper: {
        overflowY: 'unset',
    },
    paperRoot: {
        marginBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(4),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(6),
            padding: theme.spacing(4),
        },
    },
    root: {
        width: "auto",
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            // width: 600,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    footer: {
        padding: '1rem',
        textAlign: 'center',
        marginTop: 'auto'

    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        top: '-9%',
        backgroundColor: 'lightgray',
        color: 'gray',
    },

    title: {
        marginTop: theme.spacing(2)
    },
    userCard: {
        background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        color: theme.palette.common.white,
    },
    cardTitle: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        color: theme.palette.common.white,
    },
    cardSubtitle: {
        fontSize: '1rem',
    },
    userCardAvatar: {
        '&.MuiAvatar-root': {
            width: "60px",
            height: "60px",
            marginBottom: "5px"
        },
    },
        center: {
        textAlign: "center",
    },
    cursor: {
        cursor: "pointer",
    },



})); 

export default useStyles;