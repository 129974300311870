
import { UserCard } from "../../components/Card";
import ConfirmationDialog from "./ConfirmationDialog";
import React, { useState, useContext } from "react";
import { MdClearAll } from "react-icons/md";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import { Avatar, Switch, FormControlLabel, Popover, Toolbar, AppBar } from "@mui/material";

import bn from "../../utils/bemnames";

import useCognito from "../../hooks/useCognito";
import AuthContext from "../../context/authContext";

import UrlChecker from "../HealthCheck/UrlChecker";
import PageSpinner from "../PageSpinner";


import UpdateButton from "../UpdateButton";
import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";
import { useAppContext } from "../../context/appContext";
import { useError } from "../../context/errorContext";
import DarkModeToggle from "./ToggleButton";
import { useTheme } from "@mui/material";
import useStyles from "../../constants/commonStyles";
import { StyledButton, StyledIconButton } from "../../constants/styledComponents";
import {formatName} from "../../utils/formatName" 
const bem = bn.create("header");

const Header = () => {
	
	const { signOut } = useCognito();
	const { userData } = useContext(AuthContext);
	const { handleCompatibilityToggeling, isLocalHost } = useAppContext();
	const backendStatus = useBackendStatusContext()
	const savedCompatibility = localStorage.getItem("compatibility")

	const [isRebootDialogOpen, setIsRebootDialogOpen] = useState(false);
	const [isShutdownDialogOpen, setIsShutdownDialogOpen] = useState(false);
	const [loadingReboot, setLoadingReboot] = useState(false);
	const [loadingShutdown, setLoadingShutdown] = useState(false);

	const { handleError } = useError(); 
	const theme = useTheme(); 
	const classes = useStyles(theme);

	const [state, setState] = useState({
		isOpenNotificationPopover: false,
		isNotificationConfirmed: false,
		isOpenUserCardPopover: false,
		currentUser:formatName(userData.firstName,userData.lastName),
	});

	const toggleNotificationPopover = () => {
		setState((prevState) => {
			return {
				...prevState,
				isOpenNotificationPopover: !prevState.isOpenNotificationPopover,
			};
		});

		if (state.isNotificationConfirmed) {
			setState((prevState) => {
				return {
					...prevState,
					isNotificationConfirmed: true,
				};
			});
		}
	};

	// Retrieve the CSRF token from localStorage
	const csrfToken = localStorage.getItem("csrfToken");

	const handleReboot = async () => {
		try {
			setLoadingReboot(true);
			// eslint-disable-next-line no-undef
			await fetch(`${process.env.REACT_APP_SERVER_URI}/${process.env.REACT_APP_DATA_ACQUISITION_API_VERSION}/reboot`, {
				method: "GET",
				withCredentials: true,
				credentials: "include",
				headers: {
					"X-CSRF-Token": csrfToken, // Add the CSRF token as a header
				},
			});

			// checking if reboot request was sent
			console.log("Reboot request sent successfully");
		} catch (error) {
			// showing if any errors occur during the reboot request
			console.error("Error sending reboot request", error);
			handleError(error);
		} finally {
			setLoadingReboot(false);
			setIsRebootDialogOpen(false);
		}
	};

	const handleShutdown = async () => {
		try {
			setLoadingShutdown(true);
			// eslint-disable-next-line no-undef
			await fetch(`${process.env.REACT_APP_SERVER_URI}/${process.env.REACT_APP_DATA_ACQUISITION_API_VERSION}/shutdown`, {
				method: "GET",
				withCredentials: true,
				credentials: "include",
				headers: {
					"X-CSRF-Token": csrfToken, // Add the CSRF token as a header
				},
			});

			// checking if shutdown request was sent
			console.log("Shutdown request sent successfully");
		} catch (error) {
			// showing if any errors occur during the shutdown request
			console.error("Error sending shutdown request", error);
			handleError(error);
		} finally {
			setLoadingShutdown(false);
			setIsShutdownDialogOpen(false);
		}

	};

	const toggleUserCardPopover = () => {
		setState((prevState) => {
			return {
				...prevState,
				isOpenUserCardPopover: !prevState.isOpenUserCardPopover,
			};
		});
	};

	const handleSidebarControlButton = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const sidebar = document.querySelector(".ss-sidebar");
		if (sidebar) {
			sidebar.classList.toggle("ss-sidebar--open");
		}
	};
	const handleSwitchChange = (event) => {
		const isChecked = event.target.checked;
		handleCompatibilityToggeling(isChecked)
		// eslint-disable-next-line no-restricted-globals
		location.reload()

	}


	return (
		<>
			<AppBar position="static" className={bem.b()}>
				<Toolbar className="d-flex justify-content-between">
					<div style={{display: "flex"}}>
						<StyledIconButton
							onClick={handleSidebarControlButton}
							>
							<MdClearAll size={25} />
						</StyledIconButton>
						<DarkModeToggle />
					</div>

					<div>
						<div className="d-flex align-items-center">
							{backendStatus.state.isUpdatePossible && <UpdateButton />}
							<UrlChecker
								// eslint-disable-next-line no-undef
								url={`${process.env.REACT_APP_SERVER_URI}/readiness`}
								reverse={true}
								circleSize={40}
								upAlias="READY"
								downAlias="NOT READY"
							/>
							<Avatar
								onClick={toggleUserCardPopover}
								className={classes.cursor}
							/>
							<div>
								<Popover
									open={state.isOpenUserCardPopover}
									onClose={toggleUserCardPopover}
									anchorEl={document.getElementById("Popover2")}
									anchorOrigin={{ vertical: "top", horizontal: "right" }}
									transformOrigin={{ vertical: "top", horizontal: "right" }}
									PaperProps={{ style: { minWidth: 250, marginTop: "40px" } }}
								>
									<UserCard
										// className="p-0 border-light"
										title={state.currentUser}
										// text="Last updated 3 mins ago"
										text=""
									>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "flex-start",
												flexDirection: 'column',
												paddingLeft: '7px'
											}}
										>
											<div>
												<StyledButton
													// className={classes.button}
													startIcon={<ExitToAppIcon className={classes.signOutButton} />}
													onClick={() => signOut()}
													className={classes.signOutButton}
												>
													Sign Out
												</StyledButton>
											</div>
											<div>

												<StyledButton
													startIcon={<RotateLeftIcon className={classes.signOutButton} />}
													onClick={() => setIsRebootDialogOpen(true)}
													className={classes.signOutButton}
												>
													Reboot device
												</StyledButton>

											</div>
											<div>

												<StyledButton
													startIcon={<PowerSettingsNewIcon className={classes.signOutButton} />}
													onClick={() => setIsShutdownDialogOpen(true)}
													className={classes.signOutButton}
												>
													Switch Off Device
												</StyledButton>



											</div>
										</div>


									</UserCard>

									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column"
										}}
									>
										{isLocalHost &&
											<div >

												<FormControlLabel 
													control={
														<Switch onChange={handleSwitchChange} defaultChecked={JSON.parse(savedCompatibility)}
														/>
													}

													label="Bypass compatibility"
												/>

											</div>
										}
									</div>

								</Popover>
							</div>

						</div>
					</div>
				</Toolbar>
			</AppBar>

			<ConfirmationDialog
				isOpen={isRebootDialogOpen}
				onClose={() => setIsRebootDialogOpen(false)}
				onConfirm={handleReboot}
				title={loadingReboot ? <PageSpinner color="primary" /> : "Reboot Device"}
				subtitle="Are you sure you want to reboot the device?"
			/>
			<ConfirmationDialog
				isOpen={isShutdownDialogOpen}
				onClose={() => setIsShutdownDialogOpen(false)}
				onConfirm={handleShutdown}
				title={loadingShutdown ? <PageSpinner color="primary" /> : "Switch Off Device"}
				subtitle="Are you sure you want to switch off the device?"
			/>
		</>
	);
};

export default Header;
