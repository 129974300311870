import grey from "@mui/material/colors/grey";

export const themes = {
  light: {
    mode: "light",
    primary: {
      main: "#00b398",
      contrastText: grey[100],
      dark: "#007864",
      light: "#34cbb2",
    },
    secondary: {
      main: "#007864",
      contrastText: grey[100],
      dark: "#004d40",
      light: "#48a999",
    },
    background: {
      default: "#f8f9fa",
      paper: "#f5f5f5",
      common: "#fff",
    },
    text: {
      primary: "#000",
    },
    error: {
      main: "#e53935",
    },
    neutral: {
      main: "#8695a7",
    },
    common: {
      white: "#fff",
      lightGrey: "#F2F2F2",
    },
  },
  dark: {
    mode: "dark",
    primary: {
      main: "#00b398",
      contrastText: grey[100],
      dark: "#007864",
      light: "#34cbb2",
    },
    secondary: {
      main: "#004d40",
      contrastText: grey[100],
      dark: "#007864",
      light: "#48a999",
    },
    background: {
      default: "#1E2A30",
      paper: "#1F2730",
      common: "#24333D",
    },
    text: {
      primary: "#fff",
    },
    error: {
      main: "#f44336",
    },
    neutral: {
      main: "#8695a7",
    },
    common: {
      white: "#fff",
      lightGrey: "#394147",
    },
  },
};