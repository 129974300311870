import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material';
import useStyles from '../../constants/commonStyles';



const UserCard = ({
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  
  const theme = useTheme();
  const classes = useStyles(theme);


  return (
    <Card className={classes.userCard} {...restProps}>
        <CardContent className="d-flex justify-content-between align-items-center pl-3">
        <Avatar className={classes.userCardAvatar}/>
        <Typography  className={classes.cardTitle}>
          {title} 
        </Typography>
        <Typography className={classes.cardSubtitle}>{subtitle}</Typography>
        <Typography variant="body2">
          <small>{text}</small>
        </Typography>
      </CardContent>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};


export default UserCard;