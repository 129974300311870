import axios from 'axios'

axios.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json; charset=utf-8'
        config.headers['X-CSRF-Token'] = localStorage.getItem("csrfToken")
        config.withCredentials = true

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    res => {
        return res
    },
    error => {
        return Promise.reject(error)
    }
)

export default axios
