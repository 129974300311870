import logo200ImageWhite from "../../images/logo_white.png";
import SourceLink from "../SourceLink";
import React, { useEffect, useState } from "react";

import {
  MdHelpCenter,
  MdScatterPlot,
  MdStackedLineChart,
  MdSettings,
  MdSupportAgent,
} from "react-icons/md";

import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import bn from "../../utils/bemnames";

import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";

import { withStyles } from "@material-ui/core/styles";

const StyledListItem = withStyles({
  root: {
    "&:hover": {
      color: "white",
    },
  },
})(ListItem);

const sidebarBackground = {
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const bem = bn.create("sidebar");

const basicNavItems = [
  { to: "/support", name: "SUPPORT", exact: false, Icon: MdSupportAgent },
  { to: "/about", name: "ABOUT", exact: false, Icon: MdHelpCenter },
]

const extendedNavItems = [
  { to: "/", name: "VISUAL TESTS", exact: true, Icon: MdScatterPlot },
  { to: "/results", name: "RESULTS", exact: false, Icon: MdStackedLineChart },
  { to: "/settings", name: "SETTINGS", exact: false, Icon: MdSettings },
  { to: "/support", name: "SUPPORT", exact: false, Icon: MdSupportAgent },
  { to: "/about", name: "ABOUT", exact: false, Icon: MdHelpCenter },
]

const Sidebar = () => {
  const backendStatus = useBackendStatusContext()

  const [navItems, setNavItems] = useState(basicNavItems);

  useEffect(()=>{
    if(backendStatus.state.isAvailable && backendStatus.state.isCertificateValid || backendStatus.state.isUpdating){
      setNavItems(extendedNavItems)
    }else{
      setNavItems(basicNavItems)
    }
  }, [backendStatus.state.isAvailable, backendStatus.state.isCertificateValid, backendStatus.state.isUpdating])

  useEffect(()=>{
      setNavItems(extendedNavItems)
  }, [])

  return (
    <aside className={bem.b()} data-image={logo200ImageWhite}>
      <div className={bem.e("background")} style={sidebarBackground} />
      <div className={bem.e("content")}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <SourceLink className="d-flex" style={{ textDecoration: 'none' }}>
              <span className={bem.e("nav-item")} style={{ color: "white", display: "flex", alignItems: "center", marginLeft: "-10px" }}>
                <img
                  src={logo200ImageWhite}
                  width="20"
                  height="24"
                  className="pr-2"
                  alt=""
                  style={{ margin: "0px 8px 2px 6px" }}
                />
                <Typography variant="h6" style={{ fontWeight: "bold", fontSize: "22px" }}>SONDA</Typography>
              </span>
            </SourceLink>
          </Toolbar>
        </AppBar>
        <List>
          {navItems.map(({ to, name, exact, Icon }, index) => (
            <StyledListItem
              key={index}
              className={bem.e("nav-item")}
              component={NavLink}
              to={to}
              activeClassName="active"
              exact={exact}
              button
            >
              <ListItemIcon>
                <Icon className={bem.e("nav-item-icon")} style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary={name} style={{ textTransform: "uppercase", marginLeft: "-20px" }} />
            </StyledListItem>
          ))}
        </List>
      </div>
    </aside>
  );
}

export default Sidebar;
